var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { LANGUAGE } from './CommonDomain';
import { isDevelopmentMode } from '../envUtils';
import en from './locales/en_US.json';
import ko from './locales/ko_KR.json';
import cn from './locales/cn_CH.json';
function loadLocaleMessages(currentLanguage) {
    return __awaiter(this, void 0, void 0, function* () {
        //console.log("i18next utils loadLocaleMessages: " + currentLanguage);
        let lang = '';
        if (currentLanguage === 'ko-KR') {
            lang = 'ko_KR';
        }
        else if (currentLanguage === 'en-US') {
            lang = 'en_US';
        }
        else if (currentLanguage === 'cn-CH') {
            lang = 'cn_CH';
        }
        else {
            lang = 'en_US';
        }
        // 해당 언어의 message 파일만 import 한다.
        const data = yield import(
        // webpackChunkName: "locale-chunk" 
        `./locales/${lang}.json`);
        const locales = data.default;
        return { [currentLanguage]: locales };
    });
}
export let $ = () => { };
export function init(currentLanguage) {
    return __awaiter(this, void 0, void 0, function* () {
        return i18n
            .use(initReactI18next)
            .init({
            debug: isDevelopmentMode,
            load: 'currentOnly',
            interpolation: {
                escapeValue: true,
                prefix: '{',
                suffix: '}',
            },
            lng: currentLanguage,
            fallbackLng: LANGUAGE.KO,
            //resources: await loadLocaleMessages(currentLanguage)
            resources: {
                en_US: en,
                ko_KR: ko,
                cn_CH: cn,
            },
        })
            .then(t => {
            $ = t;
        });
    });
}
export function changeLang(currentLanguage) {
    return __awaiter(this, void 0, void 0, function* () {
        console.log("i18next utils changeLang: " + currentLanguage);
        return i18n
            .use(initReactI18next)
            .changeLanguage(currentLanguage);
    });
}
/**
 * reload error
export async function reloadLangResource(currentLanguage: string) {
    console.log("i18next utils reloadLangResource: " + currentLanguage);
    return i18n
        .use(initReactI18next)
        .reloadResources({
            resources : await loadLocaleMessages(currentLanguage)
        })
}
 */ 
