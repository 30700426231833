export const getNativeInfo = () => {
    const result = {
        result: {
            // 사용자의 언어 받아오기
            language: window.navigator.language
        }
    };
    return new Promise((resolve) => {
        resolve(result);
    });
};
